import { css } from '@emotion/react'
import { spacing } from '@orus.eu/pharaoh'
import { Header } from '@orus.eu/pharaoh/src/patterns/header'
import { memo } from 'react'
import { OrganizationsEditor } from './organizations-editor'

export default memo(function PlatformOrganizationsPage() {
  return (
    <div
      css={css`
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
      `}
    >
      <div
        css={css`
          padding: ${spacing[70]};
        `}
      >
        <Header title="Administration des organisations" />
      </div>
      <div
        css={css`
          flex-grow: 1;
          flex-shrink: 1;
        `}
      >
        <OrganizationsEditor />
      </div>
    </div>
  )
})
